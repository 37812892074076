import { useQuery } from '@apollo/client'
import { AgGridReact } from 'ag-grid-react'
import { Col, DatePicker, Row } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { GetAllAmazonOrdersDocument } from '../../../generated/graphql'
import { useDateRangePresets } from '../../../hooks/useDateRangePresets'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { AmazonOrderOverview } from './components/AmazonOrderOverview'
import { AmazonOrderTable, AmazonOrderTableRecord } from './components/AmazonOrderTable'
import { prepareAmazonOrderData } from './helpers/prepareAmazonOrderData'

const { RangePicker } = DatePicker

const defaultDateRange: [Dayjs, Dayjs] = [
  dayjs().subtract(29, 'd').startOf('d'),
  dayjs().endOf('d'),
]

export const AmazonOrdersView = () => {
  const [dateRange, setDateRange] = useState(defaultDateRange)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const gridRef = useRef<AgGridReact<AmazonOrderTableRecord> | null>(null)

  const { t } = useTranslation(['inventory', 'translation'])

  const presets = useDateRangePresets()

  const { loading, error, data } = useQuery(GetAllAmazonOrdersDocument, {
    fetchPolicy: 'no-cache',
    skip: !selectedCompany,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
      from: dateRange[0].format('YYYY-MM-DD'),
      to: dateRange[1].format('YYYY-MM-DD'),
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const amazonOrders = prepareAmazonOrderData(data)

  return (
    <>
      <SubHeader
        heading={t('orders.heading')}
        rightContent={
          <Row justify="end">
            <Col>
              <RangePicker
                value={dateRange}
                onCalendarChange={(values) => {
                  if (values?.[0] && values?.[1]) {
                    setDateRange([values[0].startOf('day'), values[1].endOf('day')])
                  }
                }}
                allowClear={false}
                disabledDate={(date) => date.isAfter(dayjs())}
                format="DD.MM.YY"
                placement="bottomRight"
                presets={presets}
              />
            </Col>
          </Row>
        }
      />

      <ViewContainer>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <AmazonOrderOverview amazonOrders={amazonOrders} />
          </Col>
        </Row>
        <AmazonOrderTable orders={amazonOrders} gridRef={gridRef} />
      </ViewContainer>
    </>
  )
}
