import { useMutation, useQuery } from '@apollo/client'
import { Alert, Button, Flex, Form, Modal, Select } from 'antd'
import { SquarePen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { GetCompanyBrandsDocument, UpdateProductBrandDocument } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'

type EditProductBrandFormInstance = {
  brand: string
}

type EditProductBrandModalProps = {
  uuid: string
  brand: string | null
}

export const EditProductBrandModal = ({ uuid, brand }: EditProductBrandModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('inventory')
  const [form] = Form.useForm<EditProductBrandFormInstance>()

  const { loading, data } = useQuery(GetCompanyBrandsDocument, {
    fetchPolicy: 'cache-first',
    skip: !selectedCompany.uuid,
    variables: { companyUuid: selectedCompany.uuid },
    onError: () => {
      return toast.error(t('shared.error.message', { ns: 'translation' }))
    },
  })

  const [updateProductBrand] = useMutation(UpdateProductBrandDocument)

  const handleSubmit = async (values: EditProductBrandFormInstance) => {
    setSubmitting(true)

    try {
      await updateProductBrand({
        variables: { uuid, brand: values.brand },
        optimisticResponse: {
          updateProduct: {
            brand: { name: values.brand },
          },
        },
        update: (cache, { data }) => {
          const updatedProduct = data?.updateProduct

          if (updatedProduct?.brand?.name) {
            const updatedBrandName = updatedProduct.brand.name

            cache.modify({
              id: cache.identify({
                __typename: 'Product',
                uuid,
              }),
              fields: {
                brand: () => ({
                  __typename: 'Brand',
                  name: updatedBrandName,
                }),
              },
            })
          }
        },
      })

      toast.success(t('product.details.product.editBrandModal.success'))
      setOpen(false)
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setSubmitting(false)
    }
  }

  const brandOptions =
    data?.brands.map((brand) => ({
      label: brand.name,
      value: brand.name,
    })) ?? []

  return (
    <>
      <Button
        type="text"
        icon={<SquarePen size={16} />}
        size="small"
        disabled={loading}
        onClick={() => setOpen(true)}
      />
      <Modal
        title={t('product.details.product.editBrandModal.title')}
        open={open}
        onOk={() => form.submit()}
        onCancel={() => setOpen(false)}
        okText={t('shared.button.submit', { ns: 'translation' })}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        confirmLoading={submitting}
        destroyOnClose
      >
        <Flex vertical gap={8}>
          <Alert type="info" message={t('product.details.product.editBrandModal.note')} />
          <Form<EditProductBrandFormInstance>
            form={form}
            initialValues={{ brand }}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              name="brand"
              label={t('product.details.product.editBrandModal.label')}
              rules={[
                { required: true, message: t('shared.form.error.required', { ns: 'translation' }) },
              ]}
            >
              <Select
                showSearch
                placeholder={t('product.details.product.editBrandModal.placeholder')}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (!option) {
                    return false
                  }

                  return option.label.toLowerCase().includes(input.toLowerCase())
                }}
                options={brandOptions}
              />
            </Form.Item>
          </Form>
        </Flex>
      </Modal>
    </>
  )
}
