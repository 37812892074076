import { skipToken, useSuspenseQuery } from '@apollo/client'
import { Col, Flex, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import {
  GetWarehouseServiceProvidersDocument,
  GetWarehouseServiceProvidersQuery,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { AddWarehouseServiceProviderModal } from './AddWarehouseServiceProviderModal'
import { WarehouseServiceProviderCard } from './WarehouseServiceProviderCard'

export type WarehouseServiceProvider =
  GetWarehouseServiceProvidersQuery['warehouseServiceProviders'][number]
export type WarehouseServiceProviderLocation = NonNullable<
  WarehouseServiceProvider['locations']
>[number]

export const WarehousingContainer = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation('warehousing')

  const { data, error } = useSuspenseQuery(
    GetWarehouseServiceProvidersDocument,
    selectedCompany?.uuid
      ? {
          fetchPolicy: 'cache-first',
          variables: {
            companyUuid: selectedCompany.uuid,
          },
        }
      : skipToken
  )

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const sortedWarehouseServiceProviders = sortWarehouseServiceProviders(data)

  return (
    <Flex vertical gap={16}>
      <Flex justify="space-between" gap={16}>
        <Typography.Text strong style={{ fontSize: '1rem' }}>
          {t('title')}
        </Typography.Text>
        <AddWarehouseServiceProviderModal />
      </Flex>
      <Row gutter={[16, 16]}>
        {sortedWarehouseServiceProviders.map((warehouseServiceProvider) => (
          <Col key={warehouseServiceProvider.uuid} xs={24} md={12} xl={8}>
            <WarehouseServiceProviderCard warehouseServiceProvider={warehouseServiceProvider} />
          </Col>
        ))}
      </Row>
    </Flex>
  )
}

function sortWarehouseServiceProviders(data: GetWarehouseServiceProvidersQuery | undefined) {
  if (!data) {
    return []
  }

  return structuredClone(data.warehouseServiceProviders).sort((a, b) => {
    if (a.createdAt < b.createdAt) {
      return 1
    }

    if (a.createdAt > b.createdAt) {
      return -1
    }

    return 0
  })
}
