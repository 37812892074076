import { skipToken, useMutation, useSuspenseQuery } from '@apollo/client'
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, TourProps } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router'
import { toast } from 'sonner'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import {
  CheckOnboardingStepDocument,
  CompanyVatIdInput,
  GetCompanyDocument,
  Marketplace,
  Pricing,
  UpdateCompanyDocument,
  UpdateCompanyInput,
} from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { omitTypename } from '../../../../helpers/omitTypename'
import { sortByMarketplace } from '../../../../helpers/sortByMarketplace'
import { useCountryOptions } from '../../../../hooks/useCountryOptions'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { useOnboardingStore } from '../../../../stores/useOnboardingStore'
import { CompanyInformationVatDataFormFields } from './CompanyInformationVatDataFormFields'

type CompanySettingsFormInstance = {
  name: string
  sellerId: string
  taxationCountry: string
  registerNumber: string
  legalForm: string
  personAccountable: string
  marketplaces: Marketplace[]
  subscriptionFee: number
  minimumFee: number
  percentageCharge: number
  accountHolder: string
  nameOfBank: string
  iban: string
  bic: string
  addressBillingFullName: string
  addressBillingPhone: string
  addressBillingLine1: string
  addressBillingLine2: string
  addressBillingCountry: string
  addressBillingState: string
  addressBillingCity: string
  addressBillingZip: string
  addressRemissionFullName: string
  addressRemissionPhone: string
  addressRemissionLine1: string
  addressRemissionLine2: string
  addressRemissionCountry: string
  addressRemissionState: string
  addressRemissionCity: string
  addressRemissionZip: string
  pricing: Pricing
  vatIds: CompanyVatIdInput[]
}

export const CompanySettingsForm = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const [temporaryVatIds, setTemporaryVatIds] = useState<Set<string>>(new Set())
  const addVatButtonRef = useRef(null)
  const [searchParams] = useSearchParams()
  const setTourSteps = useOnboardingStore((state) => state.setTourSteps)
  const setTourOpen = useOnboardingStore((state) => state.setTourOpen)

  const { t } = useTranslation(['translation', 'company'])
  const [form] = Form.useForm()

  const countryOptions = useCountryOptions()

  const steps: TourProps['steps'] = [
    {
      title: t('company:onboarding.addVatNumber.title'),
      description: t('company:onboarding.addVatNumber.description'),
      target: () => addVatButtonRef.current,
      nextButtonProps: {
        style: { display: 'none' },
      },
    },
  ]

  const { error, data } = useSuspenseQuery(
    GetCompanyDocument,
    selectedCompany?.uuid
      ? {
          fetchPolicy: 'cache-first',
          variables: { companyUuid: selectedCompany.uuid },
        }
      : skipToken
  )

  const [updateCompany, { loading }] = useMutation(UpdateCompanyDocument)
  const [checkOnboardingStep] = useMutation(CheckOnboardingStepDocument)

  useEffect(() => {
    const tour = searchParams.get('tour-vat-number')
    if (tour === 'true') {
      setTourSteps(steps)
      setTourOpen(true)
    }
  }, [searchParams])

  if (error) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  const handleSave = async (values: CompanySettingsFormInstance) => {
    try {
      if (!data?.company) {
        throw new Error()
      }
      setTemporaryVatIds(new Set())

      const companyUuid = data.company.uuid
      const formattedCompany = formatUpdateCompanyInput(values)

      await updateCompany({
        variables: {
          companyUuid,
          company: formattedCompany,
        },
        optimisticResponse: {
          // @ts-ignore
          updateCompany: {
            ...data.company,
            ...formattedCompany,
          },
        },
        update: (cache, { data }) => {
          const updatedCompany = data?.updateCompany
          const cachedQuery = cache.readQuery({
            query: GetCompanyDocument,
            variables: {
              companyUuid,
            },
          })

          if (updatedCompany && cachedQuery) {
            cache.writeQuery({
              query: GetCompanyDocument,
              variables: {
                companyUuid,
              },
              data: {
                company: {
                  ...cachedQuery.company,
                  ...updatedCompany,
                },
              },
            })
          }
        },
      })

      if (!selectedCompany.completedOnboarding) {
        await checkOnboardingStep({
          variables: {
            input: {
              companyUuid: selectedCompany.uuid,
              eventName: 'add_vat_number',
            },
          },
          refetchQueries: [
            {
              query: GetCompanyDocument,
              variables: { companyUuid: selectedCompany.uuid },
            },
          ],
        })
      }
      toast.success(t('settings.company.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message'))
    }
  }

  return (
    <>
      <Form<CompanySettingsFormInstance>
        name="company-settings"
        layout="vertical"
        initialValues={omitTypename(data?.company)}
        onFinish={handleSave}
        form={form}
      >
        <Row gutter={32}>
          <Col xs={24} lg={12}>
            <Divider orientation="left" orientationMargin={0}>
              {t('settings.company.generalInformation')}
            </Divider>
            <Form.Item label={t('settings.company.name')} name="name">
              <Input disabled />
            </Form.Item>
            <Form.Item label={t('settings.company.sellerId')} name="sellerId">
              <Input disabled />
            </Form.Item>
            <Form.Item label={t('settings.company.registerNumber')} name="registerNumber">
              <Input disabled />
            </Form.Item>
            <Form.Item label={t('settings.company.legalForm')} name="legalForm">
              <Input disabled />
            </Form.Item>
            <Form.Item label={t('settings.company.personAccountable')} name="personAccountable">
              <Input disabled />
            </Form.Item>
            <Divider orientation="left" orientationMargin={0}>
              {t('settings.company.pricingInformation')}
            </Divider>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={t('settings.company.minimumFee')}
                  name={['pricing', 0, 'minimumFee']}
                >
                  <Input prefix="EUR" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('settings.company.percentageCharge')}
                  name={['pricing', 0, 'percentageCharge']}
                >
                  <Input prefix="%" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label={t('settings.company.marketplaces')} name="marketplaces">
              <Checkbox.Group disabled>
                <Row gutter={[8, 8]}>
                  {Object.values(Marketplace)
                    .sort((a, b) => sortByMarketplace(a, b))
                    .map((marketplace) => (
                      <Col span={12} key={marketplace}>
                        <Checkbox value={marketplace}>
                          Amazon.{getMarketplaceDomain(marketplace)}
                        </Checkbox>
                      </Col>
                    ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Divider orientation="left" orientationMargin={0}>
              {t('settings.company.bankInformation')}
            </Divider>
            <Form.Item label={t('settings.company.accountHolder')} name="accountHolder">
              <Input disabled />
            </Form.Item>
            <Form.Item label={t('settings.company.nameOfBank')} name="nameOfBank">
              <Input disabled />
            </Form.Item>
            <Form.Item label={t('settings.company.iban')} name="iban">
              <Input disabled />
            </Form.Item>
            <Form.Item label={t('settings.company.bic')} name="bic">
              <Input disabled />
            </Form.Item>
            <Divider orientation="left" orientationMargin={0}>
              {t('settings.company.taxInformation')}
            </Divider>
            <Form.Item label={t('settings.company.taxationCountry')} name="taxationCountry">
              <Input disabled />
            </Form.Item>
            <CompanyInformationVatDataFormFields
              form={form}
              ref={addVatButtonRef}
              temporaryVatIds={temporaryVatIds}
              setTemporaryVatIds={setTemporaryVatIds}
            />
          </Col>
          <Col xs={24}>
            <Row gutter={32}>
              <Col xs={24} lg={12}>
                <Divider orientation="left" orientationMargin={0}>
                  {t('settings.company.billingAddress')}
                </Divider>
                <Form.Item
                  label={t('settings.company.addressFullName')}
                  name="addressBillingFullName"
                  rules={[
                    { required: true, message: t('shared.form.error.required'), whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('settings.company.addressPhone')}
                  name="addressBillingPhone"
                  rules={[
                    { required: true, message: t('shared.form.error.required'), whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('settings.company.addressLine1')}
                  name="addressBillingLine1"
                  rules={[
                    { required: true, message: t('shared.form.error.required'), whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label={t('settings.company.addressLine2')} name="addressBillingLine2">
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('settings.company.addressCountry')}
                  name="addressBillingCountry"
                  rules={[{ required: true, message: t('shared.form.error.required') }]}
                >
                  <Select<string>
                    options={countryOptions.map((country) => ({
                      value: country.code,
                      label: country.name,
                    }))}
                    placeholder={t('shared.form.placeholder.select')}
                    showSearch
                    filterOption={true}
                    optionFilterProp="label"
                  />
                </Form.Item>
                <Form.Item label={t('settings.company.addressState')} name="addressBillingState">
                  <Input />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={16}>
                    <Form.Item
                      label={t('settings.company.addressCity')}
                      name="addressBillingCity"
                      rules={[
                        {
                          required: true,
                          message: t('shared.form.error.required'),
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={t('settings.company.addressZip')}
                      name="addressBillingZip"
                      rules={[
                        {
                          required: true,
                          message: t('shared.form.error.required'),
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} lg={12}>
                <Divider orientation="left" orientationMargin={0}>
                  {t('settings.company.remissionAddress')}
                </Divider>
                <Form.Item
                  label={t('settings.company.addressFullName')}
                  name="addressRemissionFullName"
                  rules={[
                    { required: true, message: t('shared.form.error.required'), whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('settings.company.addressPhone')}
                  name="addressRemissionPhone"
                  rules={[
                    { required: true, message: t('shared.form.error.required'), whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('settings.company.addressLine1')}
                  name="addressRemissionLine1"
                  rules={[
                    { required: true, message: t('shared.form.error.required'), whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label={t('settings.company.addressLine2')} name="addressRemissionLine2">
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('settings.company.addressCountry')}
                  name="addressRemissionCountry"
                  rules={[{ required: true, message: t('shared.form.error.required') }]}
                >
                  <Select<string>
                    options={countryOptions.map((country) => ({
                      value: country.code,
                      label: country.name,
                    }))}
                    placeholder={t('shared.form.placeholder.select')}
                    showSearch
                    filterOption={true}
                    optionFilterProp="label"
                  />
                </Form.Item>
                <Form.Item label={t('settings.company.addressState')} name="addressRemissionState">
                  <Input />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={16}>
                    <Form.Item
                      label={t('settings.company.addressCity')}
                      name="addressRemissionCity"
                      rules={[
                        {
                          required: true,
                          message: t('shared.form.error.required'),
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={t('settings.company.addressZip')}
                      name="addressRemissionZip"
                      rules={[
                        {
                          required: true,
                          message: t('shared.form.error.required'),
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row justify="end">
              <Divider />
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t('shared.button.save')}
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  )
}

function formatUpdateCompanyInput(company: CompanySettingsFormInstance): UpdateCompanyInput {
  return {
    addressRemissionFullName: company.addressRemissionFullName,
    addressRemissionPhone: company.addressRemissionPhone,
    addressRemissionLine1: company.addressRemissionLine1,
    addressRemissionLine2: company.addressRemissionLine2,
    addressRemissionCountry: company.addressRemissionCountry,
    addressRemissionState: company.addressRemissionState,
    addressRemissionCity: company.addressRemissionCity,
    addressRemissionZip: company.addressRemissionZip,
    addressBillingFullName: company.addressBillingFullName,
    addressBillingPhone: company.addressBillingPhone,
    addressBillingLine1: company.addressBillingLine1,
    addressBillingLine2: company.addressBillingLine2,
    addressBillingCountry: company.addressBillingCountry,
    addressBillingState: company.addressBillingState,
    addressBillingCity: company.addressBillingCity,
    addressBillingZip: company.addressBillingZip,
    vatIds: company.vatIds,
  }
}
