import { useQuery } from '@apollo/client'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import {
  GetAllBankTransactionsDocument,
  GetAllBankTransactionsQuery,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { BankTransactionList } from './BankTransactionsList/BankTransactionList'

export type BankTransaction = GetAllBankTransactionsQuery['bankTransactions'][number]

export const BankTransactionsView = () => {
  const { t } = useTranslation(['billing', 'translation'])
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { error, data } = useQuery(GetAllBankTransactionsDocument, {
    fetchPolicy: 'cache-and-network',
    skip: !selectedCompany?.uuid,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
    },
  })

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const updatedAt = data?.bankTransactions[0]?.updated_at

  return (
    <>
      <SubHeader
        heading={t('bankTransactions.heading')}
        rightContent={
          updatedAt ? (
            <Typography.Text type="secondary">
              {t('bankTransactions.lastUpdate')} {dayjs(updatedAt).format('DD MMM YYYY')}
            </Typography.Text>
          ) : null
        }
      />
      <ViewContainer>
        <BankTransactionList bankTransactions={data?.bankTransactions} />
      </ViewContainer>
    </>
  )
}
