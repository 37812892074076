import { useQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { GetProductVariantDocument } from '../../generated/graphql'
import { AmazonProductCards } from '../amazon/products/components/AmazonProductCards'
import { ProductVariantDataCard } from './components/ProductVariantDataCard'
import { ProductVariantPackagingUnitCard } from './components/ProductVariantPackagingUnitCard'
import { TransferProductVariantModal } from './components/TransferProductVariantModal'

export const ProductVariantView = () => {
  const params = useParams<{ productUuid: string; productVariantUuid: string }>()

  const { t } = useTranslation('inventory', { keyPrefix: 'product.details' })

  const { loading, error, data } = useQuery(GetProductVariantDocument, {
    skip: !params.productVariantUuid,
    variables: {
      uuid: params.productVariantUuid!,
    },
  })

  if (error) {
    return <StatusResult status="500" title="500" subTitle={t('error.message')} />
  }

  const productVariant = data?.productVariant ?? null
  const amazonProducts = productVariant?.amazonProducts ?? []
  const packagingUnits = productVariant?.packagingUnits ?? []

  const heading = productVariant?.product
    ? `${productVariant?.product.name}${
        productVariant?.attributes?.length
          ? ` - ${productVariant?.attributes?.map((attribute) => attribute.value).join(' / ')}`
          : ''
      }`
    : undefined

  return (
    <>
      <SubHeader
        heading={heading}
        withBackButton
        onBackUrl={`/products/${params.productUuid}`}
        rightContent={
          !!productVariant && <TransferProductVariantModal productVariant={productVariant} />
        }
      />
      <ViewContainer>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <ProductVariantDataCard productVariant={productVariant} loading={loading} />
          </Col>
          <Col xs={24} xl={12}>
            <AmazonProductCards
              productVariant={productVariant}
              amazonProducts={amazonProducts}
              loading={loading}
            />
          </Col>
          {productVariant && (
            <Col xs={24}>
              <ProductVariantPackagingUnitCard
                productVariantUuid={productVariant.uuid}
                packagingUnits={packagingUnits}
                loading={loading}
              />
            </Col>
          )}
        </Row>
      </ViewContainer>
    </>
  )
}
