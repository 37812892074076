import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { GetAuthenticatedUserDocument, GetAuthenticatedUserQuery } from '../generated/graphql'
import { AuthStatus, useGlobalStore } from '../stores/useGlobalStore'
import { useOnboardingStore } from '../stores/useOnboardingStore'

export type AuthenticatedUser = GetAuthenticatedUserQuery['user']
export type UserCompany = NonNullable<AuthenticatedUser['companies']>[number]

export function useAuthentication() {
  const {
    authStatus,
    setAuthStatus,
    cognitoUser,
    user,
    setUser,
    setSelectedCompany,
    selectedCompanyUuid,
  } = useGlobalStore()

  const { setOnboardingOpen } = useOnboardingStore()

  const cognitoUsername = cognitoUser?.getUsername()

  const { loading } = useQuery(GetAuthenticatedUserDocument, {
    skip: !cognitoUsername,
    variables: { cognitoUsername: cognitoUsername! },
    onCompleted: (data) => {
      const companies = data.user.companies ?? []

      mixpanel.identify(data.user.cognitoUsername)
      mixpanel.people.set({
        email: data.user.email,
        name: `${data.user.firstName} ${data.user.lastName}`,
      })

      Sentry.setUser({
        email: data.user.email,
        name: `${data.user.firstName} ${data.user.lastName}`,
      })

      const company = selectedCompanyUuid
        ? (companies.find((company) => company.uuid === selectedCompanyUuid) ?? companies[0])
        : companies[0]

      if (!company) {
        throw new Error('User has no companies!')
      }

      if (!company.completedOnboarding) {
        setOnboardingOpen(true)
      }

      setAuthStatus(AuthStatus.AUTHENTICATED)
      setUser(data.user)
      setSelectedCompany(company)
    },
    onError: (error) => {
      console.error(error)
      setAuthStatus(AuthStatus.UNAUTHENTICATED)
      setUser(null)
      setSelectedCompany(null)
    },
  })

  return {
    loading: loading || authStatus === AuthStatus.PENDING,
    user,
  }
}
