import { useMutation, useQuery } from '@apollo/client'
import { Button, Flex, Form, FormListFieldData, Input, Modal, Select } from 'antd'
import { Plus, SquarePen, Trash } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  GetProductDocument,
  GetProductVariantDocument,
  GetProductVariantOptionsDocument,
  GetProductVariantQuery,
  UpdateProductVariantAttributesDocument,
} from '../../../../../generated/graphql'

type EditProductVariantAttributesFormInstance = {
  attributes: ProductVariantAttributeFormValues[]
}

type ProductVariantAttributeFormValues = {
  option: string
  value: string
}

type EditProductVariantAttributesModalProps = {
  productVariant: GetProductVariantQuery['productVariant']
}

export const EditProductVariantAttributesModal = ({
  productVariant,
}: EditProductVariantAttributesModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { t } = useTranslation('inventory')
  const [form] = Form.useForm<EditProductVariantAttributesFormInstance>()

  const [updateProductVariantAttributes] = useMutation(UpdateProductVariantAttributesDocument)

  const handleFinish = async (values: EditProductVariantAttributesFormInstance) => {
    try {
      setSubmitting(true)

      const attributes = values.attributes.map((attribute) => ({
        option: attribute.option,
        value: attribute.value,
      }))

      await updateProductVariantAttributes({
        variables: { uuid: productVariant.uuid, attributes },
        update: (cache, { data }) => {
          const updatedProductVariant = data?.updateProductVariant

          const cachedProductVariantQuery = cache.readQuery({
            query: GetProductVariantDocument,
            variables: { uuid: productVariant.uuid },
          })

          if (updatedProductVariant && cachedProductVariantQuery) {
            cache.writeQuery({
              query: GetProductVariantDocument,
              variables: { uuid: productVariant.uuid },
              data: {
                productVariant: {
                  ...cachedProductVariantQuery.productVariant,
                  attributes: updatedProductVariant.attributes,
                },
              },
            })
          }

          const cachedProductQuery = cache.readQuery({
            query: GetProductDocument,
            variables: { uuid: productVariant.product.uuid },
          })

          if (updatedProductVariant && cachedProductQuery) {
            cache.writeQuery({
              query: GetProductDocument,
              variables: { uuid: productVariant.product.uuid },
              data: {
                product: {
                  ...cachedProductQuery.product,
                  productVariants: cachedProductQuery.product.productVariants?.map(
                    (productVariant) => {
                      if (productVariant.uuid === updatedProductVariant.uuid) {
                        return {
                          ...productVariant,
                          attributes: updatedProductVariant.attributes,
                        }
                      }

                      return productVariant
                    }
                  ),
                },
              },
            })
          }
        },
      })

      toast.success(t('product.details.productVariant.editProductVariantAttributesModal.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('product.details.productVariant.editProductVariantAttributesModal.error'))
    } finally {
      setSubmitting(false)
      setOpen(false)
    }
  }

  const initialValues = useMemo(() => {
    return productVariant.attributes
      ? {
          attributes: productVariant.attributes.map((attribute) => ({
            option: attribute.option,
            value: attribute.value,
          })),
        }
      : { attributes: [] }
  }, [productVariant.attributes])

  return (
    <>
      <Button
        icon={<SquarePen size={16} />}
        onClick={() => setOpen(true)}
        type="text"
        size="small"
      />
      <Modal
        title={t('product.details.productVariant.editProductVariantAttributesModal.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => {
          form.resetFields()
          setOpen(false)
        }}
        confirmLoading={submitting}
      >
        <Form<EditProductVariantAttributesFormInstance>
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleFinish}
          style={{ width: '100%' }}
        >
          <Form.List name="attributes">
            {(attributeFields, { add: addOption, remove: removeOption }) => (
              <Flex vertical gap={8}>
                <Flex vertical gap={8}>
                  {attributeFields.map((attributeField) => (
                    <ProductVariantAttributeFormCard
                      key={attributeField.key}
                      attributeField={attributeField}
                      removeOption={removeOption}
                    />
                  ))}
                </Flex>
                {attributeFields.length < 3 && (
                  <Button
                    type="link"
                    icon={<Plus size={16} />}
                    onClick={() => addOption({ option: undefined, value: '' })}
                    style={{ alignSelf: 'flex-start' }}
                  >
                    {t('product.create.variant.attribute.add')}
                  </Button>
                )}
              </Flex>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  )
}

const ProductVariantAttributeFormCard = ({
  attributeField,
  removeOption,
}: {
  attributeField: FormListFieldData
  removeOption: (index: number | number[]) => void
}) => {
  const form = Form.useFormInstance<EditProductVariantAttributesFormInstance>()
  const attributes = Form.useWatch<EditProductVariantAttributesFormInstance['attributes']>(
    'attributes',
    form
  )

  const { t } = useTranslation('inventory')

  const { loading, data } = useQuery(GetProductVariantOptionsDocument, {
    fetchPolicy: 'cache-first',
    onError: () => toast.error(t('shared.error.message', { ns: 'translation' })),
  })

  return (
    <Flex gap={8} align="end">
      <Flex gap={8} style={{ width: '100%' }}>
        <Form.Item<EditProductVariantAttributesFormInstance['attributes']>
          name={[attributeField.name, 'option']}
          label={t('product.create.variant.attribute.label')}
          rules={[
            {
              validateTrigger: ['onBlur', 'onChange'],
              validator: () => {
                const uniqueAttributes = new Set(
                  attributes?.map((attribute) => attribute.option).filter(Boolean)
                )

                if (
                  uniqueAttributes.size !==
                  attributes?.map((attribute) => attribute.option).filter(Boolean).length
                ) {
                  return Promise.reject(t('product.create.variant.attribute.validation.duplicate'))
                }

                return Promise.resolve()
              },
            },
            {
              message: t('product.create.variant.attribute.validation.required'),
              required: true,
              validateTrigger: ['onBlur'],
            },
          ]}
          validateTrigger={['onBlur', 'onChange']}
          style={{ flex: 1 }}
        >
          <Select<ProductVariantAttributeFormValues['option']>
            options={data?.productVariantOptions?.map((option) => ({
              value: option,
              label: option,
            }))}
            placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
            loading={loading}
            allowClear
            showSearch
            filterOption={true}
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item<ProductVariantAttributeFormValues['value']>
          name={[attributeField.name, 'value']}
          label={t('product.create.variant.value.label')}
          rules={[{ required: true, whitespace: true }]}
          style={{ flex: 1 }}
        >
          <Input placeholder={t('product.create.variant.value.placeholder')} />
        </Form.Item>
      </Flex>
      <Form.Item>
        <Button
          icon={<Trash size={16} />}
          onClick={() => removeOption(attributeField.name)}
          style={{ flexShrink: 0 }}
        />
      </Form.Item>
    </Flex>
  )
}
