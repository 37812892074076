import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import {
  GetAllAmazonProductsDocument,
  GetAllAmazonProductsQuery,
  InternalAmazonListingStatus,
} from '../../../generated/graphql'
import { getSearchIdentifiers } from '../../../helpers/getSearchIdentifiers'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import {
  AmazonProductTable,
  AmazonProductTableRecord,
} from './components/AmazonProductTable/AmazonProductTable'
import { CreateAmazonProductModal } from './components/CreateAmazonProductModal'

export const AmazonProductsView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('inventory')

  const { error, data } = useQuery(GetAllAmazonProductsDocument, {
    skip: !selectedCompany.uuid,
    variables: {
      uuid: selectedCompany.uuid,
      internalStatuses: [
        InternalAmazonListingStatus.ACTIVE,
        InternalAmazonListingStatus.REVIEW,
        InternalAmazonListingStatus.PROCESSING,
        InternalAmazonListingStatus.DELETED,
      ],
    },
  })

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const dataSource = data ? prepareAmazonProductTableData(data) : undefined

  const productVariants = data?.products.flatMap((product) =>
    product.productVariants?.flatMap((productVariant) => {
      if (productVariant.amazonProducts?.length) {
        return []
      }

      return {
        name: product.name,
        ean: productVariant.ean,
        internalSku: productVariant.internalSku,
        productVariantUuid: productVariant.uuid,
      }
    })
  )

  return (
    <>
      <SubHeader
        heading={t('amazonProduct.heading', { ns: 'inventory' })}
        rightContent={<CreateAmazonProductModal productVariants={productVariants} />}
      />
      <ViewContainer>
        <AmazonProductTable dataSource={dataSource} />
      </ViewContainer>
    </>
  )
}

export function prepareAmazonProductTableData(
  data: GetAllAmazonProductsQuery
): AmazonProductTableRecord[] {
  return data.products.flatMap((product) => {
    if (!product.productVariants) {
      return []
    }

    return product.productVariants.flatMap((productVariant) => {
      if (!productVariant.amazonProducts) {
        return []
      }

      return productVariant.amazonProducts.flatMap((amazonProduct) => {
        if (!amazonProduct.amazonListings) {
          return []
        }

        return amazonProduct.amazonListings.flatMap((amazonListing) => {
          return {
            productUuid: product.uuid,
            productVariantUuid: productVariant.uuid,
            amazonProductUuid: amazonProduct.uuid,
            amazonListingUuid: amazonListing.uuid,
            internalSku: productVariant.internalSku ?? null,
            ean: productVariant.ean ?? null,
            asin: amazonProduct.asin,
            sku: amazonListing.sku,
            fnsku: amazonListing.fnsku ?? null,
            marketplace: amazonListing.marketplace,
            mainImage: amazonListing.images?.mainImage ?? null,
            title: amazonListing.title ?? null,
            recommendedRetailPrice: amazonListing.recommendedRetailPrice ?? null,
            salePrice: amazonListing.salePrice ?? null,
            saleStartDate: amazonListing.saleStartDate ?? null,
            saleEndDate: amazonListing.saleEndDate ?? null,
            subscribeAndSave: amazonProduct.subscribeAndSave ?? null,
            changeRequests: amazonListing.changeRequests ?? [],
            mainCategory: amazonListing.mainCategory ?? null,
            subCategory: amazonListing.subCategory ?? null,
            internalStatus: amazonListing.internalStatus,
            externalStatus: amazonListing.externalStatus,
            isBuyBoxWinner: amazonListing.isBuyBoxWinner,
            panEUOfferStatus: amazonListing.panEUOfferStatus,
            labelType: amazonListing.labelType,
            isEligibleForInbound: amazonListing.isEligibleForInbound,
            isFbm: amazonListing.isFbm ?? false,
            inventory: amazonListing.inventory,
            searchIdentifiers: getSearchIdentifiers({ productVariant }),
            mostRecentShipmentDestination: amazonListing.mostRecentShipmentDestination ?? null,
          }
        })
      })
    })
  })
}
