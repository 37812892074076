import { useQuery } from '@apollo/client'
import { Plus } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { LinkButton } from '../../components/LinkButton'
import TicketList from '../../components/Ticket/TicketList'
import { SupportTicketsDocument } from '../../generated/graphql'
import { useGlobalStore } from '../../stores/useGlobalStore'

const ListView = () => {
  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation(['ticket', 'translation'])

  const { error, data } = useQuery(SupportTicketsDocument, {
    fetchPolicy: 'cache-and-network',
    skip: !cognitoUser || !selectedCompany?.uuid,
    variables: {
      cognitoUsername: cognitoUser!.getUsername(),
      companyUuid: selectedCompany!.uuid,
    },
  })

  if (error && !data) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={error.message ? error.message : t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  return (
    <>
      <SubHeader
        heading={t('listTicketView.heading')}
        rightContent={
          <LinkButton to={'new'} icon={<Plus size={16} />}>
            {t('listTicketView.button.new')}
          </LinkButton>
        }
      />
      <ViewContainer>
        <TicketList rowData={data?.supportTickets} />
      </ViewContainer>
    </>
  )
}

export default ListView
