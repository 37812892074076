import { useMutation, useQuery } from '@apollo/client'
import { Col, Flex, Row, Space, Tag } from 'antd'
import { Storage } from 'aws-amplify'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'sonner'
import { Flag } from '../../components/Flag'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import {
  S3DocumentListCard,
  S3DocumentListCardPropsData,
} from '../../components/S3/S3DocumentListCard'
import {
  DeleteProductDocumentDocument,
  GetProductDocument,
  GetProductQuery,
} from '../../generated/graphql'
import { getMarketplaceDomain } from '../../helpers/getMarketplaceDomain'
import { AmazonProductCards } from '../amazon/products/components/AmazonProductCards'
import { ArchiveProductModal } from './components/ArchiveProductModal/ArchiveProductModal'
import { ProductDataCard } from './components/ProductDataCard'
import {
  getProductDocumentTypeLabel,
  ProductDocumentType,
  ProductDocumentUploadModal,
} from './components/ProductDocumentUploadModal'
import { ProductVariantPackagingUnitCard } from './components/ProductVariantPackagingUnitCard'
import { ProductVariantsCard } from './components/ProductVariantsCard'

const bucketName = import.meta.env.VITE_AWS_S3_BUCKET_NAME_PRODUCT_DOCUMENTS

export const ProductView = () => {
  const params = useParams<{ productUuid: string }>()

  const { t } = useTranslation(['inventory', 'translation'])

  const { loading, error, data, refetch } = useQuery(GetProductDocument, {
    fetchPolicy: 'cache-first',
    skip: !params.productUuid,
    variables: {
      uuid: params.productUuid!,
    },
  })

  const [deleteProductDocument] = useMutation(DeleteProductDocumentDocument)

  if (error) {
    return <StatusResult status="500" title="500" subTitle={t('product.details.error.message')} />
  }

  const product = data?.product
  const productVariants = product?.productVariants ?? []
  const amazonProducts = productVariants[0]?.amazonProducts ?? []

  const documents: S3DocumentListCardPropsData =
    product?.documents?.map((document) => ({
      key: document.key,
      description: <ProductDocumentCardListDescription document={document} t={t} />,
    })) || []

  const handleDeleteDocument = async (key: string) => {
    try {
      await Storage.remove(key, {
        bucket: bucketName,
      })

      await deleteProductDocument({
        variables: {
          productUuid: params.productUuid!,
          key,
        },
      })

      await refetch()
      toast.success(t('product.details.documents.delete.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('product.details.documents.delete.error'))
    }
  }

  return (
    <>
      <SubHeader
        heading={product?.name}
        withBackButton
        onBackUrl="/products"
        rightContent={!!product && <ArchiveProductModal product={product} />}
      />
      <ViewContainer>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Flex gap={16} vertical>
              <ProductDataCard product={product} loading={loading} />
              <S3DocumentListCard
                bucketName={bucketName}
                title={t('product.details.documents.title')}
                rightHeaderContent={
                  product && (
                    <ProductDocumentUploadModal
                      uuid={product.uuid}
                      productName={product.name}
                      refetch={refetch}
                    />
                  )
                }
                data={documents}
                loading={loading}
                showPreview
                showViewButton
                showDownloadButton
                deleteFunction={handleDeleteDocument}
              />
            </Flex>
          </Col>
          <Col xs={24} xl={12}>
            {productVariants.length > 1 ? (
              <ProductVariantsCard product={product} loading={loading} />
            ) : (
              <AmazonProductCards amazonProducts={amazonProducts} loading={loading} />
            )}
          </Col>
          {productVariants.length === 1 && productVariants[0] && (
            <Col xs={24}>
              <ProductVariantPackagingUnitCard
                productVariantUuid={productVariants[0].uuid}
                packagingUnits={productVariants[0].packagingUnits}
                loading={loading}
              />
            </Col>
          )}
        </Row>
      </ViewContainer>
    </>
  )
}

const ProductDocumentCardListDescription = ({
  document,
  t,
}: {
  document: NonNullable<GetProductQuery['product']['documents']>[number]
  t: TFunction<'inventory'>
}) => (
  <Flex gap={8} align={'flex-start'} wrap={'wrap'}>
    <Tag style={{ margin: 0 }}>
      {getProductDocumentTypeLabel(document.type as ProductDocumentType, t)}
    </Tag>
    {document.country && (
      <Tag style={{ margin: 0 }}>
        <Space>
          <Flag code={document.country} />
          <span>Amazon.{getMarketplaceDomain(document.country)}</span>
        </Space>
      </Tag>
    )}
  </Flex>
)
