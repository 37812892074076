import { AmazonProductTableRecord } from './AmazonProductTable'

export const getIsOutOfStock = (data: AmazonProductTableRecord | undefined) => {
  if (!data) {
    return false
  }

  const fulfillableQuantity = data.inventory?.fulfillable?.fulfillableQuantity ?? 0
  const inboundQuantity =
    (data.inventory?.inbound?.inboundWorkingQuantity ?? 0) +
    (data.inventory?.inbound?.inboundShippedQuantity ?? 0) +
    (data.inventory?.inbound?.inboundReceivingQuantity ?? 0)

  const isOutOfStock = fulfillableQuantity + inboundQuantity === 0

  return isOutOfStock
}
