import { useQuery } from '@apollo/client'
import { Segmented } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GetDashboardPerformanceOverviewDocument,
  InternalAmazonListingStatus,
} from '../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { StyledMainCard } from '../../../styles'
import { prepareLatestOrders } from '../../helpers/prepareLatestOrders'
import { DashboardOrdersList } from '../DashboardOrdersList'

enum StatusFilter {
  ALL = 'All',
  SHIPPED = 'SHIPPED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
}

export const DashboardCardLatestOrders = () => {
  const [statusFilter, setStatusFilter] = useState<string | number>(StatusFilter.ALL)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const { t } = useTranslation(['dashboard'], {
    keyPrefix: 'performance.latestOrders',
  })

  const { loading, data } = useQuery(GetDashboardPerformanceOverviewDocument, {
    fetchPolicy: 'no-cache',
    pollInterval: 1000 * 60 * 15,
    skip: !selectedCompany?.uuid,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
      limit: 100,
      internalStatuses: Object.values(InternalAmazonListingStatus),
    },
  })

  const latestOrdersData = prepareLatestOrders(data)
  const dataSource =
    statusFilter === StatusFilter.ALL
      ? latestOrdersData
      : latestOrdersData.filter((order) => order.status === statusFilter)

  return (
    <StyledMainCard
      title={t('title')}
      extra={
        <Segmented
          disabled={loading}
          value={statusFilter}
          onChange={setStatusFilter}
          options={[
            { value: StatusFilter.ALL, label: t('statusFilter.all') },
            { value: StatusFilter.SHIPPED, label: t('statusFilter.shipped') },
            { value: StatusFilter.PENDING, label: t('statusFilter.pending') },
            { value: StatusFilter.CANCELED, label: t('statusFilter.canceled') },
          ]}
        />
      }
      style={{ height: '420px' }}
    >
      <DashboardOrdersList orders={dataSource} loading={loading} statusFilter={statusFilter} />
    </StyledMainCard>
  )
}
