import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { GetPaymentsDocument, GetPaymentsQuery } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { Payments } from './components/Payments'

export type PaymentsQueryShipment = GetPaymentsQuery['shipments'][number]
export type PaymentsQueryRefund = GetPaymentsQuery['refunds'][number]
export type PaymentsQueryAdjustment = GetPaymentsQuery['adjustments'][number]
export type PaymentsQueryAmazonRemovalOrderItem =
  GetPaymentsQuery['amazonRemovalOrderItems'][number]
export type PaymentsQueryStorageFee = GetPaymentsQuery['storageFees'][number]
export type PaymentsQueryAmazonLongTermStorageFee =
  GetPaymentsQuery['amazonLongTermStorageFees'][number]
export type PaymentsQueryAmazonPpcCosts = GetPaymentsQuery['amazonPpcCosts']
export type PaymentsQueryAmazonInboundShipment = GetPaymentsQuery['amazonInboundShipments'][number]

export const PaymentsView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation()

  const { loading, error, data } = useQuery(GetPaymentsDocument, {
    fetchPolicy: 'cache-and-network',
    skip: !selectedCompany?.uuid,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
      yearMonth: dayjs().format('YYYYMM'),
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error || !data) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  return <Payments dataCurrent={data} />
}
