import { useQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorWithRetry } from '../../../../components/ErrorWithRetry'
import {
  GetPerformanceMetricsDocument,
  GetPerformanceMetricsQuery,
  Marketplace,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { dashboardDateRanges } from '../DashboardFinanceView'
import { addMissingDatesToData } from '../helpers/addMissingDatesToData'
import { calculateAmazonProfit } from '../helpers/calculateAmazonProfit'
import { DashboardFinancePerformanceCard } from './DashboardFinancePerformanceCard'

export type FinancePerformanceCardData = {
  sales: number
  prevSales: number
  unitsSold: number
  prevUnitsSold: number
  amazonProfit: number
  prevAmazonProfit: number
}

type DashboardFinancePerformanceCardsContainerProps = {
  marketplaces: Marketplace[]
}

export const DashboardFinancePerformanceCardsContainer = ({
  marketplaces,
}: DashboardFinancePerformanceCardsContainerProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation()

  const [dateRange] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(dashboardDateRanges[dashboardDateRanges.length - 1]! * 2, 'day'),
    dayjs().subtract(1, 'day'),
  ])

  const { loading, error, data, refetch } = useQuery(GetPerformanceMetricsDocument, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
      marketplaces,
      dateRange: {
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
      },
    },
  })

  if (error) {
    return <ErrorWithRetry retry={refetch} />
  }

  const dataSource = preparePerformanceCardsData(dateRange, data, marketplaces)

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12} xl={6}>
        <DashboardFinancePerformanceCard
          title={t('shared.timeFrame.yesterday')}
          timeFrame={dayjs().subtract(1, 'day').format('DD.MM.YYYY')}
          dataSource={dataSource[0]!}
          loading={loading}
        />
      </Col>
      <Col xs={24} sm={12} xl={6}>
        <DashboardFinancePerformanceCard
          title={t('shared.timeFrame.last7Days')}
          timeFrame={`${dayjs().subtract(7, 'day').format('DD.MM.YYYY')} - ${dayjs()
            .subtract(1, 'days')
            .format('DD.MM.YYYY')}`}
          dataSource={dataSource[1]!}
          loading={loading}
        />
      </Col>
      <Col xs={24} sm={12} xl={6}>
        <DashboardFinancePerformanceCard
          title={t('shared.timeFrame.last30Days')}
          timeFrame={`${dayjs().subtract(30, 'day').format('DD.MM.YYYY')} - ${dayjs()
            .subtract(1, 'days')
            .format('DD.MM.YYYY')}`}
          dataSource={dataSource[2]!}
          loading={loading}
        />
      </Col>
      <Col xs={24} sm={12} xl={6}>
        <DashboardFinancePerformanceCard
          title={t('shared.timeFrame.last90Days')}
          timeFrame={`${dayjs().subtract(90, 'day').format('DD.MM.YYYY')} - ${dayjs()
            .subtract(1, 'days')
            .format('DD.MM.YYYY')}`}
          dataSource={dataSource[3]!}
          loading={loading}
        />
      </Col>
    </Row>
  )
}

function preparePerformanceCardsData(
  dateRange: [Dayjs, Dayjs],
  data?: GetPerformanceMetricsQuery,
  marketplaces?: Marketplace[]
): FinancePerformanceCardData[] {
  if (!data || !marketplaces?.length) {
    return []
  }

  const dataCopy = { ...data }

  dataCopy.performanceMetrics = addMissingDatesToData(dateRange, dataCopy)

  const formattedMetrics = dataCopy.performanceMetrics.flatMap((metric) => {
    if (!metric) {
      return []
    }

    const date = dayjs(metric.date).format('MM.DD')
    const sales = metric.sales ?? 0
    const unitsSold = metric.unitsSold ?? 0
    const refunds = metric.refunds ?? 0
    const fees = metric.fees ?? 0
    const promotions = metric.promotions ?? 0
    const sponsoredDisplayCosts = metric.sponsoredDisplayCosts ?? 0
    const sponsoredProductsCosts = metric.sponsoredProductsCosts ?? 0

    const amazonProfit = calculateAmazonProfit({
      sales,
      refunds,
      fees,
      promotions,
      sponsoredProductsCosts,
      sponsoredDisplayCosts,
    })

    return {
      date,
      sales,
      unitsSold,
      amazonProfit,
    }
  })

  return dashboardDateRanges.map((days) => {
    const interval = formattedMetrics.slice(-days)
    const prevInterval = formattedMetrics.slice(-days * 2, -days)

    const { sales, unitsSold, amazonProfit } = interval.reduce<{
      sales: number
      unitsSold: number
      amazonProfit: number
    }>(
      (previousValue, currentValue) => {
        return {
          sales: previousValue.sales + currentValue.sales,
          unitsSold: previousValue.unitsSold + currentValue.unitsSold,
          amazonProfit: previousValue.amazonProfit + currentValue.amazonProfit,
        }
      },
      { sales: 0, unitsSold: 0, amazonProfit: 0 }
    )

    const { prevSales, prevUnitsSold, prevAmazonProfit } = prevInterval.reduce<{
      prevSales: number
      prevUnitsSold: number
      prevAmazonProfit: number
    }>(
      (previousValue, currentValue) => {
        return {
          prevSales: previousValue.prevSales + currentValue.sales,
          prevUnitsSold: previousValue.prevUnitsSold + currentValue.unitsSold,
          prevAmazonProfit: previousValue.prevAmazonProfit + currentValue.amazonProfit,
        }
      },
      { prevSales: 0, prevUnitsSold: 0, prevAmazonProfit: 0 }
    )

    return {
      sales,
      prevSales,
      unitsSold,
      prevUnitsSold,
      amazonProfit,
      prevAmazonProfit,
    }
  })
}
