import { useMutation } from '@apollo/client'
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import { Plus } from 'lucide-react'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { Flag } from '../../../../components/Flag'
import {
  CompanyType,
  CreateAmazonListingDocument,
  CreateAmazonProductDocument,
  LabelType,
  Marketplace,
} from '../../../../generated/graphql'
import { formatPercentage } from '../../../../helpers/formatPercentage'
import { getMarketplaceCurrency } from '../../../../helpers/getMarketplaceCurrency'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { asinRegExp } from '../../../../utils/regExp'
import { StyledSubCard } from '../../../dashboard/styles'

type CreateAmazonProductFormInstance = {
  productVariantUuid: string
  asin: string
  labelType: LabelType
  subscribeAndSave: number
  marketplaces: Partial<
    Record<
      Marketplace,
      {
        price: number
        shouldCreate: boolean
      }
    >
  >
}

type ProductVariantSelect = {
  name: string
  ean: string | null | undefined
  internalSku: string | null | undefined
  productVariantUuid: string
}

type CreateAmazonProductModalProps = {
  productVariants: (ProductVariantSelect | undefined)[] | undefined
}

export const CreateAmazonProductModal = ({ productVariants }: CreateAmazonProductModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const marketplaceOptions = useMarketplaceOptions()
  const { t } = useTranslation('inventory')
  const [form] = useForm<CreateAmazonProductFormInstance>()

  const [createAmazonProduct] = useMutation(CreateAmazonProductDocument)
  const [createAmazonListing] = useMutation(CreateAmazonListingDocument)

  const hasMarketplaces = marketplaceOptions.length > 0

  const handleFinish = async (values: CreateAmazonProductFormInstance) => {
    try {
      setSubmitting(true)

      const marketplacesWithPrice = Object.entries(values.marketplaces ?? {})
        .filter(([, value]) => value.shouldCreate)
        .map(([marketplace, { price }]) => ({
          marketplace: marketplace as Marketplace,
          price,
        }))

      const sku = `MISSING-SKU-${nanoid(10)}`
      const asin = values.asin ?? `MISSING-ASIN-${nanoid(10)}`

      const createdAmazonProduct = await createAmazonProduct({
        variables: {
          productVariantUuid: values.productVariantUuid,
          amazonProduct: {
            asin,
            amazonAccount: 'Varento',
            subscribeAndSave: values.subscribeAndSave,
          },
        },
      })

      const amazonProductUuid = createdAmazonProduct.data?.createAmazonProduct.uuid

      if (!amazonProductUuid) {
        throw new Error()
      }

      for (const { marketplace, price } of marketplacesWithPrice) {
        await createAmazonListing({
          variables: {
            amazonProductUuid,
            amazonListing: {
              sku,
              marketplace,
              ...(price && { recommendedRetailPrice: price }),
              labelType: values.labelType,
            },
          },
        })
      }

      toast.success(t('product.create.variant.salesChannels.create.toast.success'))
      form.resetFields()
      setOpen(false)
    } catch (error) {
      console.error(error)
      toast.error(t('product.create.variant.salesChannels.create.toast.error'))
    } finally {
      setTimeout(() => setSubmitting(false), 1000)
    }
  }

  return (
    <>
      <Tooltip
        title={
          !productVariants?.length ? t('product.create.variant.salesChannels.create.tooltip') : ''
        }
      >
        <Button
          icon={<Plus size={16} />}
          onClick={() => setOpen(true)}
          type="primary"
          disabled={!productVariants?.length}
        >
          {t('product.create.variant.salesChannels.create.button')}
        </Button>
      </Tooltip>
      <Modal
        title={t('product.create.variant.salesChannels.create.modal.title')}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => form.submit()}
        confirmLoading={submitting}
        okText={t('shared.button.submit', { ns: 'translation' })}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        okButtonProps={{ disabled: !hasMarketplaces }}
      >
        <Form<CreateAmazonProductFormInstance>
          name="product-form"
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          style={{ width: '100%' }}
        >
          <Flex vertical>
            <Row gutter={16}>
              <Col xs={24}>
                <Divider orientation="left" orientationMargin={0}>
                  Amazon
                </Divider>
              </Col>
              <Col xs={24}>
                <Form.Item<CreateAmazonProductFormInstance>
                  name="productVariantUuid"
                  label={t('product.create.variant.salesChannels.productVariant')}
                  rules={[{ required: true }]}
                  validateDebounce={1000}
                >
                  <Select<ProductVariantSelect>
                    options={productVariants?.map((productVariant) => ({
                      value: productVariant?.productVariantUuid,
                      label: getProductVariantSelectLabel(productVariant),
                    }))}
                    placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
                    showSearch
                    filterOption={true}
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item<CreateAmazonProductFormInstance>
                  name="asin"
                  label={t('product.create.variant.salesChannels.asin')}
                  rules={[{ pattern: asinRegExp }]}
                  validateDebounce={1000}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item<CreateAmazonProductFormInstance>
                  name="labelType"
                  label={t('product.create.variant.salesChannels.labeling')}
                  rules={[{ required: true }]}
                >
                  <Select<string>
                    options={Object.values(LabelType).map((type) => ({
                      value: type,
                      label: type,
                    }))}
                    placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item<CreateAmazonProductFormInstance>
                  name="subscribeAndSave"
                  label={t('product.create.variant.salesChannels.subscribeAndSave')}
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value={0}>
                      {t('product.create.variant.salesChannels.radioButton.no')}
                    </Radio>
                    <Radio value={5}>{formatPercentage(0.05)}</Radio>
                    <Radio value={10}>{formatPercentage(0.1)}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              {marketplaceOptions.map((marketplace) => (
                <Col xs={24} sm={12} key={marketplace}>
                  <ProductVariantAmazonProductFormCard marketplace={marketplace} />
                </Col>
              ))}
            </Row>
            {!hasMarketplaces && (
              <Alert
                type="warning"
                showIcon
                description={t('product.create.variant.salesChannels.create.noMarketplaces')}
              />
            )}
          </Flex>
        </Form>
      </Modal>
    </>
  )
}

const ProductVariantAmazonProductFormCard = ({ marketplace }: { marketplace: Marketplace }) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const showRRP = selectedCompany.type !== CompanyType.WHOLESALER
  const shouldCreate: boolean | undefined = useWatch(['marketplaces', marketplace, 'shouldCreate'])

  return (
    <StyledSubCard
      title={
        <Space>
          <Flag code={marketplace} />
          <Typography.Text>{`Amazon.${getMarketplaceDomain(marketplace)}`}</Typography.Text>
        </Space>
      }
      extra={
        <Form.Item<CreateAmazonProductFormInstance>
          name={['marketplaces', marketplace, 'shouldCreate']}
          valuePropName="checked"
          noStyle
        >
          <Checkbox />
        </Form.Item>
      }
      hideSeparator={!showRRP}
    >
      {showRRP && (
        <Form.Item<CreateAmazonProductFormInstance>
          name={['marketplaces', marketplace, 'price']}
          noStyle
        >
          <InputNumber
            addonBefore={getMarketplaceCurrency(marketplace)}
            disabled={!shouldCreate}
            min={0}
            max={999999}
            placeholder="0.00"
            precision={2}
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}
    </StyledSubCard>
  )
}

function getProductVariantSelectLabel(productVariant: ProductVariantSelect | undefined): string {
  if (!productVariant) {
    return ''
  }

  const { name, ean, internalSku } = productVariant
  const labelParts = [name, ean, internalSku].filter(Boolean)

  return labelParts.join(' - ')
}
