import { skipToken, useMutation, useSuspenseQuery } from '@apollo/client'
import { Button, Col, Divider, Form, Input, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { GetUserDocument, UpdateUserDocument } from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'

type UserSettingsFormInstance = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

export const UserSettingsForm = () => {
  const user = useGlobalStore((state) => state.user)

  const { t } = useTranslation()

  const { error, data } = useSuspenseQuery(
    GetUserDocument,
    user?.cognitoUsername
      ? {
          fetchPolicy: 'cache-first',
          variables: { cognitoUsername: user.cognitoUsername },
        }
      : skipToken
  )

  const [updateUser, { loading }] = useMutation(UpdateUserDocument)

  if (error) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  const handleSave = async (values: UserSettingsFormInstance) => {
    try {
      if (!data?.user || !user) {
        throw new Error()
      }

      const cognitoUsername = data.user.cognitoUsername

      await updateUser({
        variables: {
          cognitoUsername,
          user: { ...values, isAdmin: user.isAdmin },
        },
        optimisticResponse: {
          updateUser: {
            cognitoUsername,
            ...values,
          },
        },
        update: (cache, { data }) => {
          const updatedUser = data?.updateUser
          const cachedQuery = cache.readQuery({
            query: GetUserDocument,
            variables: {
              cognitoUsername,
            },
          })

          if (updatedUser && cachedQuery) {
            cache.writeQuery({
              query: GetUserDocument,
              variables: {
                cognitoUsername,
              },
              data: {
                user: {
                  ...cachedQuery.user,
                  ...updatedUser,
                },
              },
            })
          }
        },
      })
      toast.success(t('settings.user.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message'))
    }
  }

  return (
    <>
      <Form<UserSettingsFormInstance>
        name="user-settings"
        layout="vertical"
        initialValues={data?.user}
        onFinish={handleSave}
      >
        <Divider orientation="left" orientationMargin={0}>
          {t('settings.user.generalInformation')}
        </Divider>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t('settings.user.firstName')}
                  name="firstName"
                  rules={[
                    { required: true, message: t('shared.form.error.required'), whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t('settings.user.lastName')}
                  name="lastName"
                  rules={[
                    { required: true, message: t('shared.form.error.required'), whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t('settings.user.email')}
              name="email"
              rules={[
                { required: true, message: t('shared.form.error.required'), whitespace: true },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('settings.user.phoneNumber')}
              name="phoneNumber"
              rules={[
                { required: true, message: t('shared.form.error.required'), whitespace: true },
              ]}
            >
              <Input />
            </Form.Item>
            <Row justify="end">
              <Divider />
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t('shared.button.save')}
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  )
}
