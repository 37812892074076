export type SearchableItem = {
  identifier?: string | null
  productVariant?: {
    ean?: string | null
    internalSku?: string | null
    packagingUnits?: PackagingUnit[] | null
    amazonProducts?: AmazonProduct[] | null
    product?: {
      tags?: string[] | null
    } | null
  } | null
}

type PackagingUnit = {
  quantity: number
  gtin?: string | null
  internalSku?: string | null
}

type AmazonProduct = {
  asin?: string | null
  amazonListings?: AmazonListing[] | null
}

type AmazonListing = {
  sku?: string | null
  fnsku?: string | null
}

export function getSearchIdentifiers(
  items: SearchableItem | SearchableItem[] | null | undefined
): string {
  if (!items) return ''

  const searchIdentifiers = new Set<string>()
  const itemsArray = Array.isArray(items) ? items : [items]

  for (const item of itemsArray) {
    if (item.identifier) {
      searchIdentifiers.add(item.identifier)
    }

    if (!item.productVariant) {
      continue
    }

    const { product, ean, internalSku, packagingUnits, amazonProducts } = item.productVariant

    if (product?.tags) {
      for (const tag of product.tags) {
        searchIdentifiers.add(tag)
      }
    }

    for (const id of [ean, internalSku].filter(Boolean)) {
      searchIdentifiers.add(id)
    }

    if (packagingUnits) {
      for (const unit of packagingUnits) {
        if (unit.quantity === 1) {
          if (unit.gtin) {
            searchIdentifiers.add(unit.gtin)
          }

          if (unit.internalSku) {
            searchIdentifiers.add(unit.internalSku)
          }
        }
      }
    }

    if (amazonProducts) {
      for (const product of amazonProducts) {
        if (product.asin) {
          searchIdentifiers.add(product.asin)
        }

        if (product.amazonListings) {
          for (const listing of product.amazonListings) {
            if (listing.sku) {
              searchIdentifiers.add(listing.sku)
            }

            if (listing.fnsku) {
              searchIdentifiers.add(listing.fnsku)
            }
          }
        }
      }
    }
  }

  return Array.from(searchIdentifiers).join(' ')
}
