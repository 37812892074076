import { useQuery } from '@apollo/client'
import { Col, Row, Segmented } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GetDashboardPerformanceOverviewDocument,
  InternalAmazonListingStatus,
} from '../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { StyledMainCard } from '../../../styles'
import { accumulatePerformanceOverview } from '../../helpers/accumulatePerformanceOverview'
import { preparePerformanceOverview } from '../../helpers/preparePerformanceOverview'
import { DashboardSubCardOrders } from '../DashboardSubCardOrders'
import { DashboardSubCardRevenue } from '../DashboardSubCardRevenue'
import { DashboardSubCardTopMarketplaces } from '../DashboardSubCardTopMarketplaces'
import { DashboardSubCardTopProducts } from '../DashboardSubCardTopProducts'

enum TimeFrame {
  TODAY = 'Today',
  LAST_7_DAYS = 'Last 7 Days',
  LAST_30_DAYS = 'Last 30 Days',
}

export const DashboardCardPerformanceOverview = () => {
  const [timeFrame, setTimeFrame] = useState<string | number>(TimeFrame.TODAY)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const { t } = useTranslation(['dashboard', 'translation'])

  const { loading, data } = useQuery(GetDashboardPerformanceOverviewDocument, {
    fetchPolicy: 'no-cache',
    pollInterval: 1000 * 60 * 15,
    skip: !selectedCompany?.uuid,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
      from: dayjs().subtract(29, 'days').startOf('day').format('YYYY-MM-DD'),
      to: dayjs().endOf('day').format('YYYY-MM-DD'),
      internalStatuses: Object.values(InternalAmazonListingStatus),
    },
  })

  const dailyPerformanceOverview = preparePerformanceOverview(data)
  const relevantData = dailyPerformanceOverview.filter((item) => {
    if (timeFrame === TimeFrame.TODAY) {
      return dayjs(item.date).isSame(dayjs(), 'day')
    } else if (timeFrame === TimeFrame.LAST_7_DAYS) {
      return dayjs(item.date).isAfter(dayjs().subtract(7, 'days'))
    } else if (timeFrame === TimeFrame.LAST_30_DAYS) {
      return dayjs(item.date).isAfter(dayjs().subtract(30, 'days'))
    }
  })
  const dataSource = accumulatePerformanceOverview(relevantData)

  return (
    <StyledMainCard
      title={t('performance.performanceOverview.title')}
      extra={
        <Segmented
          disabled={loading}
          value={timeFrame}
          onChange={setTimeFrame}
          options={[
            {
              value: TimeFrame.TODAY,
              label: t('shared.timeFrame.today', { ns: 'translation' }),
            },
            {
              value: TimeFrame.LAST_7_DAYS,
              label: t('shared.timeFrame.last7Days', { ns: 'translation' }),
            },
            {
              value: TimeFrame.LAST_30_DAYS,
              label: t('shared.timeFrame.last30Days', { ns: 'translation' }),
            },
          ]}
        />
      }
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <DashboardSubCardOrders
            totalShipped={dataSource?.totalShipped ?? 0}
            totalPending={dataSource?.totalPending ?? 0}
            totalCanceled={dataSource?.totalCanceled ?? 0}
            loading={loading}
          />
        </Col>
        <Col xs={24} md={12}>
          <DashboardSubCardRevenue
            totalRevenue={dataSource?.totalRevenue ?? 0}
            pendingRevenue={dataSource?.pendingRevenue ?? 0}
            loading={loading}
          />
        </Col>
        <Col xs={24} md={12}>
          <DashboardSubCardTopProducts
            topProducts={dataSource?.performance.products ?? []}
            loading={loading}
          />
        </Col>
        <Col xs={24} md={12}>
          <DashboardSubCardTopMarketplaces
            topMarketplaces={dataSource?.performance.marketplaces ?? []}
            loading={loading}
          />
        </Col>
      </Row>
    </StyledMainCard>
  )
}
